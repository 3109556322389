.DetailsForm .add-button {
  margin-bottom: 14px;
  background-color: #082e65;
}

.DetailsForm .add-button:active {
  color: var(--bs-btn-active-color);
  background-color: #0d4799;
  border-color: #072857;
}

.DetailsForm .club-options-render {
  margin-bottom: 10px;
}
.DetailsForm .input-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

.DetailsForm .error-message {
  font-size: 12px;
  color: #e04654;
  padding-top: 2px;
  font-weight: 600;
}

.DetailsForm .focus-input100::before {
  background: #e04654;
}

.DetailsForm .container-contact100-form-btn button[disabled] {
  background-color: #486c9e;
}

.DetailsForm .submit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DetailsForm .loading {
  padding-right: 9px;
}

.DetailsForm .error-toast {
  color: red;
  font-weight: 500;
}

.DetailsForm .error-toast-main {
  width: 100%;
}

.DetailsForm .error-toast-header {
  width: 100%;
}

.DetailsForm .error-toast-header .error-toast-header-text {
  width: 100%;
  font-style: italic;
  color: #890b0b;
  font-size: 1rem;
  text-decoration-line: underline;
}
